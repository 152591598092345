$content-padding: 10px 10px 10px 10px;

$header-color: #f0f2f5;

$sign-in-back-color: rgb(177, 177, 177);

$input-width-size-small: 180px;

$input-width-size-middle: 240px;

$input-width-size-large: 320px;

$space-size-small: 8px;

$space-size-middle: 12px;

$space-size-large: 16px;
