@import './constant.module.scss';

.searchLayout {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 24px 24px 12px 24px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: relative;
  overflow: hidden;
}

.hiddenSearchButton {
  right: 0px;
  top: 2px;
  position: absolute;
  z-index: 1;
}

.searchItemLayout {
  display: flex;
  padding-bottom: 12px;
}

.searchFormItem {
  margin-bottom: 16px;
}

.searchFormItemLabel {
  margin-bottom: 12px;
}

.searchFormItem label, p {
  margin: 0;
  white-space: nowrap;
}

.userEditSearchForm label {
  width: 85px;
}

input.searchFilter {
  width: $input-width-size-middle;
}

input.inputSmall {
  width: $input-width-size-small;
}

input.inputMiddle {
  width: $input-width-size-middle;
}

input.inputLarge {
  width: $input-width-size-large;
}

.searchNumberFilter {
  margin-bottom: 0px;
}

.searchNumberFilterField {
  margin-bottom: 8px;
  display: block;
  width: $input-width-size-small;
}
