@import './constant.module.scss';

.authBackLayout {
  height: 100vh;
  background: $sign-in-back-color;
}

.authLayout {
  max-width: 650px;
}

.authLayout form {
  background: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
}

.authLayout h1 {
  font-size: 1.5rem;
  margin: 1rem 0 1.5rem 0;
  text-align: center;
}

.authLayout h3 {
  font-size: 1.2rem;
  margin: 1rem 0 1.5rem 0;
  text-align: center;
}

.authLayout h5 {
  text-align: center;
}

.authLayout span {
  white-space: pre-line;
}

.authLayout input.verificationCode {
  max-width: $input-width-size-small;
}

.authLayout .emailAddress {
  max-width: $input-width-size-large;
}

.authLayout span.password {
  max-width: $input-width-size-large;
}

.authLayout div.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1.5rem;
}

.message {
  text-align: center;
  padding-bottom: 24px;
}
