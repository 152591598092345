.tableLayout {
  background-color: #fff;
  padding: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.tableToolbar {
  display: flex;
  margin-bottom: 12px;
}

.tableToolbar > *:nth-child(2) {
  margin-left: auto;
}

.tableToolbar > *:nth-child(n + 3) {
  margin-left: 12px;
}

.tableLayout span, .tableLayout th {
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: center !important;
}

.tableLayout .dangerColor {
  color: #ff0000;
} 

.tableLayout .deletedRow {
  background: #fafafa;
}

.tableLayout .leaveCheckBox {
  margin: auto auto auto 0;
  padding-left: 16px;
}

button.editableCellNumberValue {
  width: 100%;
  text-align: right;
}

button.editableCellValue {
  width: 100%;
  text-align: left;
}
