@import './constant.module.scss';

.containerLayout {
  height: 100vh;
}

.headerLayout {
  padding: 0 !important;
  background: $header-color !important;
}

.headerLayout button {
  font-size: medium;
}

.contentLayout {
  padding: $content-padding
}

.breadCrumbLayout {
  padding-bottom: 12px;
  padding-left: 12px;
}

div.loadingSpin {
  position: absolute;
  top: 50%;
  left: 50%;
}

.sliderChildren {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
}

.drawerButton {
  margin: 4px 4px 0 auto;
  font-size: 18px;
}

.logoutButton {
  margin: auto 4px 24px 4px;
  width: 100%;
}

.heightSpace {
  margin: $space-size-middle 0;
}
